import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000px","width":"1000px"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSubmit()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}],"click:outside":function($event){return _vm.close()}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"cashboxCard",attrs:{"width":"500"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date)))]}}],null,true)},[_c(VForm,{ref:"form"},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Kassa nomlari")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-required no-text"},[_c('span',[_vm._v("*")])]),_c(VTextField,{attrs:{"label":"NOMI","type":"text","dense":"","outlined":"","hide-details":"","rules":[_vm.required]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-required no-text"},[_c('span',[_vm._v("*")])]),_c(VTextField,{attrs:{"type":"number","label":"BOSHLANG'ICH BALANS","dense":"","outlined":"","hide-details":"","rules":[_vm.required]},model:{value:(_vm.formData.initial_amount),callback:function ($$v) {_vm.$set(_vm.formData, "initial_amount", $$v)},expression:"formData.initial_amount"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-required no-text"},[_c('span',[_vm._v("*")])]),_c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"BOSHLANG'ICH BALANS SANASI","readonly":"","hide-details":"","outlined":"","append-icon":_vm.icons.mdiCalendar},model:{value:(_vm.formData.initial_date),callback:function ($$v) {_vm.$set(_vm.formData, "initial_date", $$v)},expression:"formData.initial_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isDate),callback:function ($$v) {_vm.isDate=$$v},expression:"isDate"}},[_c(VDatePicker,{attrs:{"color":"primary","no-title":"","first-day-of-week":1,"locale":"ru-ru"},on:{"input":function($event){_vm.isDate = false}},model:{value:(_vm.formData.initial_date),callback:function ($$v) {_vm.$set(_vm.formData, "initial_date", $$v)},expression:"formData.initial_date"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"gray","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Bekor qilish")]),_c(VBtn,{attrs:{"color":"success","type":"button","disabled":_vm.submitDisabled},on:{"click":_vm.onSubmit}},[(_vm.submitDisabled)?_c(VIcon,{staticClass:"loading-animation"},[_vm._v(" "+_vm._s(_vm.icons.mdiLoading)+" ")]):_vm._e(),_vm._v(" Saqlash ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }