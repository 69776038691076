<template>
	<!-- form dialog -->
	<v-dialog
		v-model='show'
		@keydown.enter='onSubmit()'
		@keydown.esc='close()'
		@click:outside='close()'
		max-width='1000px'
		width='1000px'
	>
		<v-card width='500' class='cashboxCard'>
			<v-form ref='form'>
				<v-card-title>
					<span class='headline'>Kassa nomlari</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>

							<v-col cols='12'>
								<h4 class='text-required no-text'><span>*</span></h4>
								<v-text-field
									label='NOMI'
									v-model='formData.name'
									type='text'
									dense
									outlined
									hide-details
									:rules='[required]'
								></v-text-field>
							</v-col>

							<v-col cols='12'>
								<h4 class='text-required no-text'><span>*</span></h4>
								<v-text-field
									type='number'
									label="BOSHLANG'ICH BALANS"
									v-model='formData.initial_amount'
									dense
									outlined
									hide-details
									:rules='[required]'
								></v-text-field>
							</v-col>

							<v-col cols='12'>
								<h4 class='text-required no-text'><span>*</span></h4>
								<v-menu v-model='isDate' :close-on-content-click='false' offset-y min-width='auto'>
									<template v-slot:activator='{ on, attrs }'>
										<v-text-field
											v-model='formData.initial_date'
											label="BOSHLANG'ICH BALANS SANASI"
											readonly
											v-bind='attrs'
											v-on='on'
											hide-details
											outlined
											:append-icon='icons.mdiCalendar'
										></v-text-field>
									</template>
									<v-date-picker
										v-model='formData.initial_date'
										color='primary'
										@input='isDate = false'
										no-title
										:first-day-of-week='1'
										locale='ru-ru'
									></v-date-picker>
								</v-menu>
							</v-col>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color='gray' outlined @click='close()'>Bekor qilish</v-btn>
								<v-btn
									color='success'
									type='button'
									@click='onSubmit'
									:disabled='submitDisabled'
								>
									<v-icon
										class='loading-animation'
										v-if='submitDisabled'
									>
										{{ icons.mdiLoading }}
									</v-icon>
									Saqlash
								</v-btn>
							</v-card-actions>

						</v-row>

					</v-container>
				</v-card-text>


			</v-form>

			<template #[`item.date`]='{ item }'> {{ item.date | date }}</template>
		</v-card>

	</v-dialog>
</template>

<script>
import { mdiCalendar, mdiPlusCircleOutline } from '@mdi/js'

// formats
import moment from 'moment'
import numeral from 'numeral'

import store from '@/store'
import CashboxStoreModule from './CashboxStoreModule'

import axios from '@axios'

import { computed, ref, watch } from '@vue/composition-api'
import { minLengthValidator, required } from '@core/utils/validation'
import Button from '../../components/button/Button.vue'

moment.locale('uz')

const MODULE_NAME = 'cashbox'

export default {
	components: {
		Button,
	},

	filters: {
		date: value => moment(value).format('D MMMM YYYY'),
		sum: value => numeral(value).format('0,0'),
		feed: value => value[1] + '/' + value[2] + '/' + value[3],
	},
	created() {
		this.loadName()
	},
	setup(props, { emit }) {
		// Register module
		if (!store.hasModule(MODULE_NAME)) {
			store.registerModule(MODULE_NAME, CashboxStoreModule)
		}

		const branch_id = computed(() => store.state.branch_id)
		watch(branch_id, val => {
			emptyFormData.place_id = val
			formData.value.place_id = val
		})

		// show, hide
		const show = ref(false)
		const open = (id = null) => {

			show.value = true

			if (id) {

				const item = JSON.parse(JSON.stringify(store.getters[`${MODULE_NAME}/getById`](id)))

				item.initial_amount = item.initialBalances[branch_id.value] ? item.initialBalances[branch_id.value].amount : 0
				item.initial_date = item.initialBalances[branch_id.value] ? item.initialBalances[branch_id.value].date : null
				delete item.initialBalances

				formData.value = Object.assign(
					{
						...emptyFormData,
					},
					item,
				)
			}

		}
		const close = () => {
			show.value = false
			form.value.resetValidation()
			formData.value = { ...emptyFormData }
		}

		const form = ref(null)
		const emptyFormData = {
			id: null,
			name: null,
			initial_date: null,
			initial_amount: null,
			place_id: branch_id.value,
		}

		const picker = new Date().toISOString().substr(0, 10)
		const isDate = ref(false)

		//validation
		const formData = ref({ ...emptyFormData })
		const selectRule = [v => !!v || 'Biron qiymatni tanlang!']
		const validate = () => {
			form.value.validate()
		}

		//form options for selects
		const selectsDatas = ref({})
		//! METHODS
		const loadName = () => {
			axios
				.get('/api/cashboxes', { params: { itemsPerPage: -1 } })
				.then(response => {
					if (response.data.success) {
						selectsDatas.value.name = response.data.data
					}
				})
				.catch(error => console.log(error))
		}

		// on form submit
		const submitDisabled = ref(false)
		const onSubmit = () => {
			if (submitDisabled.value === true)
				return
			else
				submitDisabled.value = true

			submitDisabled.value = true

			if (!form.value.validate()) {
				console.log('form inputs not valid!')

				submitDisabled.value = false
				return
			}
			if (form.value.validate()) {
				if (formData.value.id) {
					store
						.dispatch(`${MODULE_NAME}/updateRow`, formData.value)
						.then(({ message }) => {
							close()
							// emit('notify', { type: 'success', text: message })
						})
						.catch(error => {
							console.log(error)
							emit('notify', { type: 'error', text: error.message })
						})
						.finally(() => {
							submitDisabled.value = false
						})
				} else {
					store
						.dispatch(`${MODULE_NAME}/addRow`, formData.value)
						.then(({ message }) => {
							close()
							emit('notify', { type: 'success', text: message })
						})
						.catch(error => {
							console.log(error)
							emit('notify', { type: 'error', text: error.message })
						})
						.finally(() => {
							submitDisabled.value = false
						})
				}
			}
		}

		// // SubjectForm
		// const studentForm = ref(null)
		// const addStudent = (id = null) => {
		//   studentForm.value.open(id)
		// }
		// const addStudentToOptions = (row) => {
		//   selectsDatas.value.student = selectsDatas.value.student.concat([row])
		//   formData.value.student_id = row.id
		// }
		// // GroupForm
		// const groupForm = ref(null)
		// const addGroup = (id = null) => {
		//   groupForm.value.open(id)
		// }
		// const addGroupToOptions = (row) => {
		//   selectsDatas.value.group = selectsDatas.value.group.concat([row])
		//   formData.value.group_id = row.id
		// }

		return {
			form,
			picker,
			isDate,
			required,
			minLengthValidator,
			formData,
			selectsDatas,
			selectRule,
			loadName,
			validate,
			show,
			onSubmit,
			submitDisabled,
			open,
			close,

			// studentForm,
			// addStudent,
			// addStudentToOptions,
			// groupForm,
			// addGroup,
			// addGroupToOptions,

			icons: {
				mdiPlusCircleOutline,
				mdiCalendar,
			},
		}
	},
}
</script>

<style>
.v-input__append-outer {
	margin: 0 0 0 10px !important;
}

.btn-dialog-add-item {
	min-width: 60px !important;
	padding-right: 15px !important;
	padding-left: 15px !important;
	border-color: rgba(94, 86, 105, 0.15) !important;
}

.cashboxCard {
	margin-left: 35%;
}
</style>
